import React from "react";

export default function About() {
  return (
    <div>
      <div className="section-header">
        <h1>Welcome to My Skating World</h1>
      </div>
      <p>
        Hello and welcome! I'm an ice skating veteran coach of seven years now,
        and I find great joy in the success of my pupils.
        <br />
        <br />
        Before coaching, I was often found doing professional ice shows across
        Europe and within the UK.
        <br />
        <br />
        I'm active in skating competitions around the world, and have been for
        many years. I particularly enjoy the choreographing.
      </p>
      <p>
        The joy of competing myself is only matched by seeing the success of my
        pupils in their own competitions!
      </p>
    </div>
  );
}
