import React from "react";

export default function New() {
  return (
    <div>
      <div className="section-header">
        <h1>New starters welcome</h1>
      </div>
      <p>
        If you're new to skating, I'd love to take you onto the ice for the
        first time.
        <br />
        <br />
        It can be daunting to put on the skates, but once on, and with some
        guidance, they will quickly become an extension of yourself and a means
        to express who you are.
        <br />
        <br />
        It's also great to just get down to an ice arena, get some hired skates
        on, maybe even a guide penguin (you'll know it when you see it!) and
        give it a go.
      </p>
    </div>
  );
}
