import React from "react";

export default function Coaching() {
  return (
    <div>
      <div className="section-header">
        <h1>Coaching Services</h1>
      </div>
      <p>
        Age is not a factor; I've taught pupils of all ages. All that matters is
        a desire to improve and a joy of being on the ice. Each pupil has a
        bespoke learning program tailored to achieve personal goals. It doesn't
        matter if you're an experienced skater looking to reach your next
        certified level or improve a particular aspect of your skating, or a new
        starter looking to find confidence to grow.
      </p>
      <p>
        We focus on your preferences, at your own pace, with one-to-one lessons.
      </p>
    </div>
  );
}
